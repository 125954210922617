import React from 'react'
import './card.scss'
import Img from 'gatsby-image'

export default class Card extends React.Component {
  render() {

    return (
      <div className='masthead__cards grid--four container'>
        {this.props.page.pageUniqueSellingPoints !== null && this.props.page.pageUniqueSellingPoints.map((data, key) => (
            <div key={key} className='card'>
                <div className='card__content'>
                    {data.pageImage !== null && <Img fluid={data.pageImage.fluid} />}
                    <h4 className='card__title'>{data.pageFullTitle}</h4>
                    <p className='card__description'>{data.pageIntroduction}</p>
                </div>
            </div>
        ))}
      </div>
    )
  }
}

