import React from 'react'
import ModalVideo from 'react-modal-video'
import Img from 'gatsby-image'

export default class VideoPlayer extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal () {
        this.setState({isOpen: true})
    }

    render () {
        return (
            <div className="content__field content--cta">
                <div className="block__text">
                    <Img fluid={ this.props.page.videoImage.fluid } />
                    <h3 className='content__title'>{this.props.page.videoTitle}</h3>
                    <p className='content__description'>{this.props.page.videoDescription}</p>
                    <span className='button button--secondary' onClick={this.openModal}>{this.props.page.videoButtonText}</span>
                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.props.page.videoId} onClose={() => this.setState({isOpen: false})} />
                </div>
            </div>
        )
    }
}
