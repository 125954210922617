import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { linkToId } from '../../assets/js/utils'
import Card from '../card'
import ButtonIcon from '../../../static/images/button-icon.svg'
import './masthead.scss'

const Masthead = ( props )  => (
  <section className='masthead'>
    <div className='masthead__block'>
      <div className='masthead__image'>
          { props.page.pageImage &&
            <Img fluid={props.page.pageImage.fluid}/>
          }
      </div>
      <div className='masthead__container container'>
        <div className='masthead__content'>
            <h1 className='masthead__title color--kraaijeveld'>
            <span>{props.page.pageTitle}</span> {props.page.pageSubtitle}</h1>
            <h5 className='masthead__description'>{props.page.pageIntroduction.internal.content}</h5>
            <Link to={linkToId(props.page.link1Reference.id)} className='masthead__button button button--primary button--icon'>{props.page.link1Title}<ButtonIcon/></Link>
            <Link to={linkToId(props.page.link2Reference.id)} className='masthead__button button button--secondary'>{props.page.link2Title}</Link>
        </div>
      </div>
    </div>
    <Card className='masthead__cards grid--four container' {...props}/>
  </section>
)

export default Masthead
