import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import Masthead from '../components/masthead'
import Slider from '../components/slider'
import CallToAction from '../components/calltoaction'
import KeyMarkets from '../components/keyMarkets'
import VideoPlayer from '../components/videoPlayer'

const PageTemplate = ({
  data: {
      page,
      navigation,
      slider,
      cta,
      usp
  },
  pageContext: {
      locale
  }
}) => {
    return (
        <Layout locale={locale} navigation={navigation} type='homepage' page={page}>
            <Masthead locale={locale} page={page} usp={usp}/>
            <KeyMarkets markets={page}/>
            <section className='flexible-content'>
                <div className='container'>
                    <div className="content grid--one">
                        <VideoPlayer page={page}/>
                    </div>
                </div>
            </section>
            <Slider slider={slider}/>
            <CallToAction cta={cta}/>
        </Layout>
    )
}

export default PageTemplate

export const query = graphql`
    query($id: String!, $locale: String) {
        page: contentfulHomepage( id: {eq: $id}, node_locale: {eq: $locale}) {
            ...HomepageFragment
        }
        navigation: contentfulNavigation (node_locale: {eq: $locale}) {
            ...NavigationFragment
        }
        cta: allContentfulCallToAction(filter: {node_locale: {eq: $locale}, pageLocation: {eq: "global"}}) {
            edges {
                node {
                    ...CallToActionFragment
                }
            }
        }
        slider: allContentfulSlider(filter: {node_locale: {eq: $locale}}) {
            edges {
                node {
                    ...SliderFragment
                }
            }
        }
    }
`
