import React from 'react'
import './keymarkets.scss'
import { Link } from 'gatsby'
import { linkToId } from '../../assets/js/utils'

const KeyMarkets = ({
    markets
})  => {

    const keymarketsLoop =
        markets.pageKeyMarkets.map((item, key) => {
            const rowIndex = key % 2 === 0 ? 'right' : 'left'
            return (
                <div className={`keymarket keymarket--${rowIndex}`} key={key}>
                    <div className="keymarket__background">
                        { item.pageImage !== null &&
                            <div className="keymarket__image" style={{ backgroundImage: `url('${item.pageImage.fluid.src}')` }}/>
                        }
                    </div>
                    <div className="keymarket__content">
                        <div className="container keymarket__container">
                            <div className="keymarket__inner">
                                <h3 className='keymarket__title'><span className='dearjoe'>{item.pageTitle}</span> {item.pageSubtitle}</h3>
                                <Link to={linkToId(item.id)} className="button button--tertiary">{item.pageButtonText}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        })

    return (
        <section className='keymarkets'>
            {keymarketsLoop}
        </section>
    )
}

export default KeyMarkets

